import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-2`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-3`} component={lazy(() => import(`./errors/error-page-3`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-student`} component={lazy(() => import(`./authentication/student-register`))} />
        <Route path={`${AUTH_PREFIX_PATH}/print-short-report/:id`} component={lazy(() => import(`./authentication/print-short-report`))} />
        <Route path={`${AUTH_PREFIX_PATH}/print-request-document/:id`} component={lazy(() => import(`./authentication/print-request-document`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-all-request-reports`} component={lazy(() => import(`./authentication/print-all-request-reports`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-student-request-document`} component={lazy(() => import(`./authentication/print-student-request-document/`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/kg`} component={lazy(() => import(`./authentication/print-certification/kg`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/kg-design-7`} component={lazy(() => import(`./authentication/print-certification/kg-design-7`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/gr1-2-design-6`} component={lazy(() => import(`./authentication/print-certification/gr1-2-design-6`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/elmentary-design-8`} component={lazy(() => import(`./authentication/print-certification/elmentary-design-8`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/gr1-2`} component={lazy(() => import(`./authentication/print-certification/gr1-2`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/gr3-4`} component={lazy(() => import(`./authentication/print-certification/gr3-4`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/middle`} component={lazy(() => import(`./authentication/print-certification/middle`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/middle-design-9`} component={lazy(() => import(`./authentication/print-certification/middle-design-9`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/high`} component={lazy(() => import(`./authentication/print-certification/high`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-certification/high-design-10`} component={lazy(() => import(`./authentication/print-certification/high-design-10`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-models`} component={lazy(() => import(`./authentication/print-models`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-request/contract-renewal`} component={lazy(() => import(`./authentication/print-employee-request/contract-renewal`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-request/contract-termination`} component={lazy(() => import(`./authentication/print-employee-request/contract-termination`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-request/late-for-period-notice`} component={lazy(() => import(`./authentication/print-employee-request/late-for-period-notice`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-request/late-for-period-warning`} component={lazy(() => import(`./authentication/print-employee-request/late-for-period-warning`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-request/shift-notice`} component={lazy(() => import(`./authentication/print-employee-request/shift-notice`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-request/shift-warning`} component={lazy(() => import(`./authentication/print-employee-request/shift-warning`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-status-request`} component={lazy(() => import(`./authentication/print-employee-request-status`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/student-transcript`} component={lazy(() => import(`./authentication/print-student-transcript`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/sub-student-transcript`} component={lazy(() => import(`./authentication/sub-student-transcript`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/employee-application`} component={lazy(() => import(`./authentication/employee-application`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/interview-model`} component={lazy(() => import(`./authentication/interview-model`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-interview-model`} component={lazy(() => import(`./authentication/interview-model/print-interview-model`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-week-student-report`} component={lazy(() => import(`./authentication/print-week-student-report`))}/>
        <Route path={`${AUTH_PREFIX_PATH}/print-class-schedule`} component={lazy(() => import(`./authentication/print-class-schedule`))}/>
        
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
};

export default AppViews;

