export const ADD_STUDENT_DATA = 'ADD_STUDENT_DATA';
export const GET_STUDENT_DATA = 'GET_STUDENT_DATA';
export const GET_REGISTRED_STUDENT_LIST = 'GET_REGISTRED_STUDENT_LIST';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const GET_STUDENT_BY_ID = 'GET_STUDENT_BY_ID';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const GET_STUDENT_PROFILE = 'GET_STUDENT_PROFILE';
export const RESET_STUDENT_DATA = 'RESET_STUDENT_DATA';
export const GET_STUDENT_DOCS = 'GET_STUDENT_DOCS';
export const UPLOAD_STUDENT_DOCUMENT = 'UPLOAD_STUDENT_DOCUMENT';
export const GET_STUDENT_DOCS_DETAILS = 'GET_STUDENT_DOCS_DETAILS';
export const GET_PARENT_STUDENTS = 'GET_PARENT_STUDENTS';
// GET_STUDENT_PROFILE

// for Parents Data or Listing
export const GET_PARENT_LIST = 'GET_PARENT_LIST';
export const RESET_PARENT_DATA = 'RESET_PARENT_DATA';
export const GET_PARENT_BYID = 'GET_PARENT_BYID';
export const GET_PARENT_DOCS = 'GET_PARENT_DOCS';
export const UPLOAD_PARENT_DOCUMENT = 'UPLOAD_PARENT_DOCUMENT';
export const GET_PARENT_DOCS_DETAILS = 'GET_PARENT_DOCS_DETAILS';

// Student Attendance

export const GET_STUDENT_ATTENDANCE_TOTAL_VIEW_LIST = 'GET_STUDENT_ATTENDANCE_TOTAL_VIEW_LIST';
export const GET_STUDENT_MONTHLY_ATTENDANCE = 'GET_STUDENT_MONTHLY_ATTENDANCE';
export const GET_STUDENT_ABSENT_DATA = 'GET_STUDENT_ABSENT_DATA';

export const STUDENT_SEARCH = 'STUDENT_SEARCH';
export const STUDENT_SEARCHR = 'STUDENT_SEARCHR';

export const REGISTRATIONSTUDENT_SEARCH = 'REGISTRATIONSTUDENT_SEARCH';
export const RESET_REGISTRATIONSTUDENT_SEARCH = 'RESET_REGISTRATIONSTUDENT_SEARCH';

export const GET_STUDENT_ACCOUNT_STATEMENT = 'GET_STUDENT_ACCOUNT_STATEMENT';
export const RESET_GET_STUDENT_ACCOUNT_STATEMENT = 'RESET_GET_STUDENT_ACCOUNT_STATEMENT';
export const GET_BULKFEES_STUDENTLIST = 'GET_BULKFEES_STUDENTLIST';
export const GET_BULKFEES_STUDENTLIST_FAIL = 'GET_BULKFEES_STUDENTLIST_FAIL';
export const GET_STUDENT_EMP_GOVERNMENT_LIST = 'GET_STUDENT_EMP_GOVERNMENT_LIST';
export const GET_STUDENT_ACCOUNT_STATEMENT_LIST = 'GET_STUDENT_ACCOUNT_STATEMENT_LIST';
export const GET_PRE_REGISTRATION_STUDENT_LIST = 'GET_PRE_REGISTRATION_STUDENT_LIST';
export const RESET_STUDENT_BROTHER_LIST = 'RESET_STUDENT_BROTHER_LIST';
export const GET_STUDENT_BROTHER_LIST = 'GET_STUDENT_BROTHER_LIST';
export const GET_STUDENT_PENDING_AMOUNT_LIST = 'GET_STUDENT_PENDING_AMOUNT_LIST';
export const GET_LEFT_STUDENT_LIST = 'GET_LEFT_STUDENT_LIST';

export const GET_STUDENT_ACADEMICYEAR_HISTORY_LIST = "GET_STUDENT_ACADEMICYEAR_HISTORY_LIST";
export const RESET_GET_STUDENT_ACADEMICYEAR_HISTORY_LIST = "RESET_GET_STUDENT_ACADEMICYEAR_HISTORY_LIST";

export const GET_STUDENTS_WITHOUT_CLASS = "GET_STUDENTS_WITHOUT_CLASS";

// REQUEST TYPES
export const GET_ALL_REQUEST_TYPES = "GET_ALL_REQUEST_TYPES";
export const GET_ALL_REQUESTES = "GET_ALL_REQUESTES";
export const GET_REQUESTES_SUMMARY = "GET_REQUESTES_SUMMARY";
export const GET_ALL_PROGRESS_DEFINITIONS = "GET_ALL_PROGRESS_DEFINITIONS";
export const GET_GRADES_WITH_PROGRESS_REPORT = "GET_GRADES_WITH_PROGRESS_REPORT";
export const GET_PROGRESS_DEFINITIONS_CATEGORIES = "GET_PROGRESS_DEFINITIONS_CATEGORIES";
export const GET_STUDENTS_WITH_SUBJECTS_TOTAL_MARKS = "GET_STUDENTS_WITH_SUBJECTS_TOTAL_MARKS";


export const GET_ALL_HIGH_SCHOOL_TRACKS = 'GET_ALL_HIGH_SCHOOL_TRACKS';
export const GET_ALL_HIGH_SCHOOL_TRACKS_STUDENTS = 'GET_ALL_HIGH_SCHOOL_TRACKS_STUDENTS';


export const GET_STUDENTS_WITH_SUBJECT_UNITS = 'GET_STUDENTS_WITH_SUBJECT_UNITS';
export const GET_STUDENTS_WITH_SUBJECT_UNITS_MARKS = 'GET_STUDENTS_WITH_SUBJECT_UNITS_MARKS';
export const GET_ALL_STUDENTS_BIHAVIOUR = 'GET_ALL_STUDENTS_BIHAVIOUR';
export const GET_STUDENT_CERTIFICATION = 'GET_STUDENT_CERTIFICATION';
export const GET_ALL_TEACHERS_WITH_SUBJECTS = 'GET_ALL_TEACHERS_WITH_SUBJECTS';
export const GET_STUDENT_WITH_MAJOR = "GET_STUDENT_WITH_MAJOR";
export const GET_MAJORS = "GET_MAJORS";

export const GET_REFUNDS = "GET_REFUNDS";

export const GET_STUDENTS_WITH_SUBJECT_TEACHERS = "GET_STUDENTS_WITH_SUBJECT_TEACHERS";
export const HIGH_STUDENTS_REPORT_LIST = "HIGH_STUDENTS_REPORT_LIST";
export const SET_CURRENT_STUDENT = "SET_CURRENT_STUDENT";
export const GET_STUDENT_MEDICAL_SUMMARY = "GET_STUDENT_MEDICAL_SUMMARY";
export const GET_ALL_STUDENT_MEDICAL_NOTES = "GET_ALL_STUDENT_MEDICAL_NOTES";
export const GET_ALL_STUDENT_MEDICINE_LIST = "GET_ALL_STUDENT_MEDICINE_LIST";
export const GET_ALL_STUDENT_SURGERIES = "GET_ALL_STUDENT_SURGERIES";
export const GET_ALL_STUDENT_MEDICAL_EXAMS = "GET_ALL_STUDENT_MEDICAL_EXAMS";
export const GET_RETURENED_FEES = "GET_RETURENED_FEES";
export const GET_STUDENT_LIBRARY_SUMMARY = "GET_STUDENT_LIBRARY_SUMMARY";
export const GET_STUDENT_BOOKS_SUMMARY = "GET_STUDENT_BOOKS_SUMMARY";
export const GET_STUDENT_STORY_SUMMARY = "GET_STUDENT_STORY_SUMMARY";
export const STUDENT_BORROW_NEW_BOOK = "STUDENT_BORROW_NEW_BOOK";
export const GET_STUDENTS_WITH_LIBRARY_SUMMARY = "GET_STUDENTS_WITH_LIBRARY_SUMMARY";
export const GET_STUDENTS_WITH_MEDICAL_SUMMARY = "GET_STUDENTS_WITH_MEDICAL_SUMMARY";
export const GET_STUDENTS_WITH_MEDICAL_LEAVE = "GET_STUDENTS_WITH_MEDICAL_LEAVE";
export const GET_STUDENTS_WITH_MEDICAL_LEAVE_TOTAL = "GET_STUDENTS_WITH_MEDICAL_LEAVE_TOTAL";
export const STUDENT_LIBRARY_PROFILE_SEARCH_PARAMS = "STUDENT_LIBRARY_PROFILE_SEARCH_PARAMS";
export const RESET_STUDENT_LIBRARY_SEARCH_PARAMS = "RESET_STUDENT_LIBRARY_SEARCH_PARAMS";
export const STUDENT_MEDICAL_PROFILE_SEARCH_PARAMS = "STUDENT_MEDICAL_PROFILE_SEARCH_PARAMS";
export const RESET_STUDENT_MEDICAL_SEARCH_PARAMS = "RESET_STUDENT_MEDICAL_SEARCH_PARAMS";
export const GET_ALL_MEDICAL_VACANATIONS = "GET_ALL_MEDICAL_VACANATIONS";
export const GET_STUDENT_MEDICAL_VACANATIONS = "GET_STUDENT_MEDICAL_VACANATIONS";
export const GET_STUDENT_NOT_RETURNED_BOOKS = "GET_STUDENT_NOT_RETURNED_BOOKS";
export const GET_FEEZ_NEXT_YEAR = "GET_FEEZ_NEXT_YEAR";
export const GET_STUDENTS_WITHOUT_NEXT_ACDEMIC_YEAR = "GET_STUDENTS_WITHOUT_NEXT_ACDEMIC_YEAR";
export const GET_CUMULATIVE_GPA = "GET_CUMULATIVE_GPA";
export const GET_STUDENT_WITH_MORE_NINTY_PECENT = "GET_STUDENT_WITH_MORE_NINTY_PECENT";
export const GET_FINAL_GRADING_LIST_ELEMNTARY_OR_MIDDLE = "GET_FINAL_GRADING_LIST_ELEMNTARY_OR_MIDDLE";
export const GET_STUDENTS_COMULATIVE_GPA = "GET_STUDENTS_COMULATIVE_GPA";
export const GET_NEXT_YEAR_PROGRESS_DEFINITIONS = "GET_NEXT_YEAR_PROGRESS_DEFINITIONS";
export const GET_STUDENTS_WITH_ZERO_FEEZ = "GET_STUDENTS_WITH_ZERO_FEEZ";
export const GET_STUDENTS_WITHOUT_CODE = "GET_STUDENTS_WITHOUT_CODE";
export const GET_KG_INTERVIEW_MODELS = "GET_KG_INTERVIEW_MODELS";
export const GET_INTERVIEW_MODEL_BY_ID = "GET_INTERVIEW_MODEL_BY_ID";
export const GET_STUDENTS_STATUS = "GET_STUDENTS_STATUS";
export const GET_PARENT_WIVES = "GET_PARENT_WIVES";
export const SET_STMENT_LIST_SEARCH = "SET_STMENT_LIST_SEARCH";
export const GET_STUDENT_ACCOUNT_STATEMENT_REVIEW_REQUEST_LIST = "GET_STUDENT_ACCOUNT_STATEMENT_REVIEW_REQUEST_LIST";