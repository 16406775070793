import {
  GET_SCHEDULE_PERIODS,
  GET_SCHOOL_TIMETABLE,
  GET_DAYS_OF_WEEKS,
  GET_CLASS_SCHEDULE,
  GET_TEACHER_SCHEDULE
} from '../constants/Schedule';

const initState = {
  periods:{},
  timeTable:{},
  daysOfWeek:[],
  classSchedule:[],
  teacherSchedule:[]
};

const schedule = (state = initState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_PERIODS:
      return {
        ...state,
        periods: action.payload.data
      }
      case GET_SCHOOL_TIMETABLE:
        return {
          ...state,
          timeTable: action.payload.data
        }
      case GET_DAYS_OF_WEEKS:
        return {
          ...state,
          daysOfWeek: action.payload.data
        }
      case GET_CLASS_SCHEDULE:
        return {
          ...state,
          classSchedule: action.payload.data || []
        }
        
      case GET_TEACHER_SCHEDULE:
        return {
          ...state,
          teacherSchedule: action.payload.data || []
        }

    default:
      return state;
  }
};

export default schedule