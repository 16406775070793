import {
  GET_SCHOOL_DATA,
  GET_SCHOOL_YEAR,
  GET_SCHOOL_GRADE_TYPE,
  GET_ROLE,
  GET_GRADES,
  GET_GRADES_STUDY_START_DATE,
  GET_JOBS,
  GET_DOC_TYPES,
  GET_SCHOOL_CLASS,
  GET_MASTER_FEES_LIST,
  GET_FEESTYPE_LIST,
  GET_GRADE_FEES_LIST,
  GET_PERCENTAGE_DISCOUNT_LIST,
  GET_BROTHERPERCENTAGE_DISCOUNT_LIST,
  GET_SCHOOL_DEPARTMENTS,
  GET_SCHOOL_SECTIONS,
  GET_SCHOOL_SUBJECTS,
  GET_NEXT_YAER_SCHOOL_SUBJECTS,
  GET_SCHOOL_GRADING_DEFINITIONS,
  GET_SCHOOL_TRACKS,
  GET_SCHOOL_TERM_PERIOD,
  GET_SCHOOL_SUBJECT_ASSESSMENTS,
  GET_SCHOOL_SUBJECT_TEACHERS,
  GET_PEEMISSIONS,
  GET_ALL_GROUPS,
  GET_ALL_ICONS,
  GET_STUDENTS_WITH_SKILL_MARKS,
  GET_ABSENT_STUDENTS,
  GET_FAIL_STUDENTS,
  GET_HONOR_STUDENTS,
  GET_WEAK_STUDENTS,
  GET_CANCELED_PAYMENT,
  GET_BLOOD_TYPES,
  GET_SPECIALITIES,
  GET_SPECIALITIES_TYPES,
  GET_MEDICINES,
  GET_ISSUES,
  GET_CLINICS,
  SET_CURRENT_DEFINITION,
  GET_ALL_LIBRARY_SECTIONS,
  GET_ALL_LIBRARY_BOOKS,
  GETSCHOOL_GALLERY,
  SET_CURRENT_LIBRARY_BOOK,
  GET_STORE_MEDICINE,
  GET_CONFIRMED_SUBJECT_MARK,
  GET_GRDUATION_CERTIFICATION,
  GET_NEXT_YEAR_SUBJECT_TEACHERS,
  GET_NEXT_YEAR_TRACKS,
  GET_DAILY_PAYMENTS_WITH_PAYMENT_HISTORY,
  GET_ASSESSMENTS_SUBJECT,
  GET_MARK_STATISTICS,
  GET_STUDENT_MARKS,
  GET_EXPECTED_STUDENT_COUNT,
} from "../constants/AdminArea";

const initState = {
  schoolData: [],

  schoolYearStatus: false,
  schoolYearMsg: "",
  schoolYear: [],
  schoolYearTotalItems: 0,
  schoolYearCurrentPage: 0,

  schoolGradesTypes: [],
  schoolGradesTypesStatus: false,
  schoolGradesTypesMsg: "",
  schoolGradesTotalItems: 0,
  schoolGradesCurrentPage: 0,

  role: [],
  roleStatus: false,
  roleMsg: "",
  roleTotalItems: 0,
  roleCurrentPage: 0,

  grades: [],
  gradesStatus: false,
  gradesMsg: "",
  gradesTotalItems: 0,
  gradesCurrentPage: 0,

  grades_study_start_date: [],
  grades_study_start_dateStatus: false,
  grades_study_start_dateMsg: "",
  grades_study_start_dateTotalItems: 0,
  grades_study_start_dateCurrentPage: 0,

  jobs: [],
  jobsStatus: false,
  jobsMsg: "",
  jobsTotalItems: 0,
  jobsCurrentPage: 0,

  docTypes: [],
  docTypesStatus: false,
  docTypesMsg: "",
  docTotalItems: 0,
  docCurrentPage: 0,

  schoolClass: [],
  schoolClassStatus: false,
  schoolClassMsg: "",
  schoolClassTotalItems: 0,
  schoolClassCurrentPage: 0,

  // Master Fees Listing
  masterFeesList: [],
  masterFeesListStatus: false,
  masterFeesListMsg: "",
  masterFeesListTotalItems: 0,
  masterFeesListCurrentPage: 0,

  // Fees Types Listing
  feesTypeList: [],
  feesTypeListStatus: false,
  feesTypeListMsg: "",
  feesTypeListTotalItems: 0,
  feesTypeListCurrentPage: 0,

  // Grade Fees Listing
  gradeFeesList: [],
  gradeFeesListStatus: false,
  gradeFeesListMsg: "",
  gradeFeesListTotalItems: 0,
  gradeFeesListCurrentPage: 0,

  // Percentage Discount
  percentageDiscountList: [],
  percentageDiscountListStatus: false,
  percentageDiscountListMsg: "",
  percentageDiscountListTotalItems: 0,
  percentageDiscountListCurrentPage: 0,

  // Brother Percentage Discount
  brotherPercentageDiscountList: [],
  brotherPercentageDiscountListStatus: false,
  brotherPercentageDiscountListMsg: "",
  brotherPercentageDiscountListTotalItems: 0,
  brotherPercentageDiscountListCurrentPage: 0,

  // School  Department
  schoolSystemDepartmenList: [],
  schoolSystemDepartmenListStatus: false,
  schoolSystemDepartmenListMsg: "",
  schoolSystemDepartmenListTotalItems: 0,
  schoolSystemDepartmenListCurrentPage: 0,

  // School  SectionS
  schoolSystemSectionListList: [],
  schoolSystemSectionListListStatus: false,
  schoolSystemSectionListListMsg: "",
  schoolSystemSectionListListTotalItems: 0,
  schoolSystemSectionListListCurrentPage: 0,

  // School Subjects
  schoolSubjectsList: [],
  schoolSubjectsListStatus: false,
  schoolSubjectsListMsg: "",
  schoolSubjectsListTotalItems: 0,
  schoolSubjectsListCurrentPage: 0,

  // next year subjects

  nextYearSchoolSubjectsList: [],

  // School GradingDefinition
  schoolGradingDefinitionList: [],
  schoolGradingDefinitionListStatus: false,
  schoolGradingDefinitionListMsg: "",
  schoolGradingDefinitionListTotalItems: 0,
  schoolGradingDefinitionListCurrentPage: 0,

  // School  Tracks
  schoolSystemTracksList: [],
  schoolSystemTracksListStatus: false,
  schoolSystemTracksListMsg: "",
  schoolSystemTracksListTotalItems: 0,
  schoolSystemTracksListCurrentPage: 0,

  // School  Tracks
  schoolSystemTermPeriodList: [],
  schoolSystemTermPeriodListStatus: false,
  schoolSystemTermPeriodListMsg: "",
  schoolSystemTermPeriodListTotalItems: 0,
  schoolSystemTermPeriodListCurrentPage: 0,

  // School SubjectAssessments
  schoolSystemSubjectAssessmentsList: [],
  schoolSystemSubjectAssessmentsListStatus: false,
  schoolSystemSubjectAssessmentsListMsg: "",
  schoolSystemSubjectAssessmentsListTotalItems: 0,
  schoolSystemSubjectAssessmentsListCurrentPage: 0,

  // School SubjectAssessments
  schoolSystemSubjectTeacherList: [],
  schoolSystemSubjectTeacherListStatus: false,
  schoolSystemSubjectTeacherListMsg: "",
  schoolSystemSubjectTeacherListTotalItems: 0,
  schoolSystemSubjectTeacherListCurrentPage: 0,

  // Groups And Permissions
  allGroupPermissions: [],
  allGroups: [],

  // Icons
  appIcons: [],

  studentsWithSkillMarks: [],
  teacherSkillMark: {},
  absentStudents: [],
  absentSudentsLength: 0,
  failStudents: [],
  failStudentsLength: 0,
  honorStudents: [],
  honorStudentsLength: 0,
  weakStudents: [],
  weakStudentsLength: 0,
  canceledPayment: [],
  bloodTypes: [],
  specialities: [],
  clinics: [],
  currentDefinition: null,
  librarySections: [],
  libraryBooks: [],
  schoolGallery: {},
  currentLibraryBook: {},
  storeMedicine:[],
  confirmedSubjects:[],
  graduationCertifications:[],
  nextYearSubjectTeacherList:[],
  nextYearTracksList:[],
  dailyPaymentWithPaymentHistory:[],
  assesmentsSubjects:[],
  mark_statistics:{},
  studentsMarks:{},
  expectedStudentCount: []
};

const adminArea = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SCHOOL_DATA:
      return {
        ...state,
        schoolData: payload,
      };
    case GET_SCHOOL_YEAR:
      return {
        ...state,
        schoolYear: payload?.data?.result || [],
        schoolYearStatus: payload?.status || false,
        schoolYearMsg: payload?.msg || "Something Went Wrong",
        schoolYearTotalItems: payload?.data?.totalItems || 0,
        schoolYearCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_GRADE_TYPE:
      return {
        ...state,
        schoolGradesTypes: payload?.data?.result || [],
        schoolGradesTypesStatus: payload?.status || false,
        schoolGradesTypesMsg: payload?.msg || "Something Went Wrong",
        schoolGradesTotalItems: payload?.data?.totalItems || 0,
        schoolGradesCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_ROLE:
      return {
        ...state,
        role: payload?.data?.result || [],
        roleStatus: payload?.status || false,
        roleMsg: payload?.msg || "Something Went Wrong",
        roleTotalItems: payload?.data?.totalItems || 0,
        roleCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_GRADES:
      return {
        ...state,
        grades: payload?.data?.result || [],
        gradesStatus: payload?.status || false,
        gradesMsg: payload?.msg || "Something Went Wrong",
        gradesTotalItems: payload?.data?.totalItems || 0,
        gradesCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_GRADES_STUDY_START_DATE:
      return {
        ...state,
        grades_study_start_date: payload?.data?.result || [],
        grades_study_start_dateStatus: payload?.status || false,
        grades_study_start_dateMsg: payload?.msg || "Something Went Wrong",
        grades_study_start_dateTotalItems: payload?.data?.totalItems || 0,
        grades_study_start_dateCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: payload?.data?.result || [],
        jobsStatus: payload?.status || false,
        jobsMsg: payload?.msg || "Something Went Wrong",
        jobsTotalItems: payload?.data?.totalItems || 0,
        jobsCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_DOC_TYPES:
      return {
        ...state,
        docTypes: payload?.data?.result || [],
        docTypesStatus: payload?.status || false,
        docTypesMsg: payload?.msg || "Something Went Wrong",
        docTypesTotalItems: payload?.data?.totalItems || 0,
        docTypesCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_CLASS:
      return {
        ...state,
        schoolClass: payload?.data?.result || [],
        schoolClassStatus: payload?.status || false,
        schoolClassMsg: payload?.msg || "Something Went Wrong",
        schoolClassTotalItems: payload?.data?.totalItems || 0,
        schoolClassCurrentPage: payload?.data?.currentPage || 0,
        schoolClassOffset: payload?.data?.offset || 0,
      };

    case GET_MASTER_FEES_LIST:
      return {
        ...state,
        masterFeesList: payload?.data?.result || [],
        masterFeesListStatus: payload?.status || false,
        masterFeesListMsg: payload?.msg || "Something Went Wrong",
        masterFeesListTotalItems: payload?.data?.totalItems || 0,
        masterFeesListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_FEESTYPE_LIST:
      return {
        ...state,
        feesTypeList: payload?.data?.result || [],
        feesTypeListStatus: payload?.status || false,
        feesTypeListMsg: payload?.msg || "Something Went Wrong",
        feesTypeListTotalItems: payload?.data?.totalItems || 0,
        feesTypeListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_GRADE_FEES_LIST:
      return {
        ...state,
        gradeFeesList: payload?.data?.result || [],
        gradeFeesListStatus: payload?.status || false,
        gradeFeesListMsg: payload?.msg || "Something Went Wrong",
        gradeFeesListTotalItems: payload?.data?.totalItems || 0,
        gradeFeesListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_PERCENTAGE_DISCOUNT_LIST:
      return {
        ...state,
        percentageDiscountList: payload?.data?.result || [],
        percentageDiscountListStatus: payload?.status || false,
        percentageDiscountListMsg: payload?.msg || "Something Went Wrong",
        percentageDiscountListTotalItems: payload?.data?.totalItems || 0,
        percentageDiscountListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_BROTHERPERCENTAGE_DISCOUNT_LIST:
      return {
        ...state,
        brotherPercentageDiscountList: payload?.data?.result || [],
        brotherPercentageDiscountListStatus: payload?.status || false,
        brotherPercentageDiscountListMsg:
          payload?.msg || "Something Went Wrong",
        brotherPercentageDiscountListTotalItems: payload?.data?.totalItems || 0,
        brotherPercentageDiscountListCurrentPage:
          payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_DEPARTMENTS:
      return {
        ...state,
        schoolSystemDepartmentList: payload?.data?.result || [],
        schoolSystemDepartmentListStatus: payload?.status || false,
        schoolSystemDepartmentListMsg: payload?.msg || "Something Went Wrong",
        schoolSystemDepartmentListTotalItems: payload?.data?.totalItems || 0,
        schoolSystemDepartmentListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_SECTIONS:
      return {
        ...state,
        schoolSystemSectionList: payload?.data?.result || [],
        schoolSystemSectionListStatus: payload?.status || false,
        schoolSystemSectionListMsg: payload?.msg || "Something Went Wrong",
        schoolSystemSectionListTotalItems: payload?.data?.totalItems || 0,
        schoolSystemSectionListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_SUBJECTS:
      return {
        ...state,
        schoolSubjectsList: payload?.data?.result || [],
        schoolSubjectsListStatus: payload?.status || false,
        schoolSubjectsListMsg: payload?.msg || "Something Went Wrong",
        schoolSubjectsListTotalItems: payload?.data?.totalItems || 0,
        schoolSubjectsListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_NEXT_YAER_SCHOOL_SUBJECTS:
      return {
        ...state,
        nextSchoolSubjectsList: payload?.data?.result || [],
      };
    
    case GET_SCHOOL_GRADING_DEFINITIONS:
      return {
        ...state,
        schoolGradingDefinitionList: payload?.data?.result || [],
        schoolGradingDefinitionListStatus: payload?.status || false,
        schoolGradingDefinitionListMsg: payload?.msg || "Something Went Wrong",
        schoolGradingDefinitionListTotalItems: payload?.data?.totalItems || 0,
        schoolGradingDefinitionListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_TRACKS:
      return {
        ...state,
        // School  Tracks
        schoolSystemTracksList: payload?.data?.result || [],
        schoolSystemTracksListStatus: payload?.status || false,
        schoolSystemTracksListMsg: payload?.msg || "Something Went Wrong",
        schoolSystemTracksListTotalItems: payload?.data?.totalItems || 0,
        schoolSystemTracksListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_NEXT_YEAR_TRACKS:
      return {
        ...state,
        // School  Tracks
        nextYearTracksList: payload?.data?.result || [],
      };
      
    case GET_SCHOOL_TERM_PERIOD:
      return {
        ...state,
        // School  Tracks
        schoolSystemTermPeriodList: payload?.data?.result || [],
        schoolSystemTermPeriodListStatus: payload?.status || false,
        schoolSystemTermPeriodListMsg: payload?.msg || "Something Went Wrong",
        schoolSystemTermPeriodListTotalItems: payload?.data?.totalItems || 0,
        schoolSystemTermPeriodListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_SUBJECT_ASSESSMENTS:
      return {
        ...state,
        // School  Tracks
        schoolSystemSubjectAssessmentsList: payload?.data?.result || [],
        schoolSystemSubjectAssessmentsListStatus: payload?.status || false,
        schoolSystemSubjectAssessmentsListMsg:
          payload?.msg || "Something Went Wrong",
        schoolSystemSubjectAssessmentsListTotalItems:
          payload?.data?.totalItems || 0,
        schoolSystemSubjectAssessmentsListCurrentPage:
          payload?.data?.currentPage || 0,
      };
    case GET_SCHOOL_SUBJECT_TEACHERS:
      return {
        ...state,
        schoolSystemSubjectTeacherList: payload?.data?.result || [],
        schoolSystemSubjectTeacherListStatus: payload?.status || false,
        schoolSystemSubjectTeacherListMsg:
          payload?.msg || "Something Went Wrong",
        schoolSystemSubjectTeacherListTotalItems:
          payload?.data?.totalItems || 0,
        schoolSystemSubjectTeacherListCurrentPage:
          payload?.data?.currentPage || 0,
      };
    case GET_NEXT_YEAR_SUBJECT_TEACHERS:
      return {
        ...state,
        nextYearSubjectTeacherList: payload?.data?.result || [],
      };
      
    case GET_PEEMISSIONS:
      return {
        ...state,
        allGroupPermissions: payload ? payload : [],
      };
    case GET_ALL_GROUPS:
      return {
        ...state,
        allGroups: payload?.result || [],
      };
    case GET_ALL_ICONS:
      return {
        ...state,
        appIcons: payload ? payload : [],
      };
    case GET_STUDENTS_WITH_SKILL_MARKS:
      return {
        ...state,
        studentsWithSkillMarks: payload?.data?.students || [],
        teacherSkillMark: payload?.data?.teacherSubjectMark || {},
      };
    case GET_ABSENT_STUDENTS:
      return {
        ...state,
        absentStudents: payload?.data?.result || [],
        absentSudentsLength: payload?.data?.totalItems || 0,
      };
    case GET_FAIL_STUDENTS:
      return {
        ...state,
        failStudents: payload?.data?.result || [],
        failStudentsLength: payload?.data?.totalItems || 0,
      };
    case GET_HONOR_STUDENTS:
      return {
        ...state,
        honorStudents: payload?.data?.result || [],
        honorStudentsLength: payload?.data?.totalItems || 0,
      };
    case GET_WEAK_STUDENTS:
      return {
        ...state,
        weakStudents: payload?.data?.result || [],
        weakStudentsLength: payload?.data?.totalItems || 0,
      };
    case GET_CANCELED_PAYMENT:
      return {
        ...state,
        canceledPayment: payload?.data?.result || [],
      };
    case GET_BLOOD_TYPES:
      return {
        ...state,
        bloodTypes: payload?.data || [],
      };
    case GET_SPECIALITIES:
      return {
        ...state,
        specialities: payload?.data || [],
      };
    case GET_SPECIALITIES_TYPES:
      return {
        ...state,
        specialitiesTypes: payload?.data || [],
      };
    case GET_MEDICINES:
      return {
        ...state,
        medicinesList: payload?.data || [],
      };
    case GET_ISSUES:
      return {
        ...state,
        issuesList: payload?.data || [],
      };
    case GET_CLINICS:
      return {
        ...state,
        clinics: payload?.data || [],
      };
    case SET_CURRENT_DEFINITION:
      return {
        ...state,
        currentDefinition: payload?.data || null,
      };
    case GET_ALL_LIBRARY_SECTIONS:
      return {
        ...state,
        librarySections: payload?.data || [],
      };
    case GET_ALL_LIBRARY_BOOKS:
      return {
        ...state,
        libraryBooks: payload?.data?.result || [],
      };
    case GETSCHOOL_GALLERY:
      return {
        ...state,
        schoolGallery: payload?.data || [],
      };

    case SET_CURRENT_LIBRARY_BOOK:
      return {
        ...state,
        currentLibraryBook: payload?.data || {},
      };
    case GET_STORE_MEDICINE:
      return {
        ...state,
        storeMedicine: payload?.data || [],
      };
    case GET_CONFIRMED_SUBJECT_MARK:
      return {
        ...state,
        confirmedSubjects: payload?.data?.result || [],
      };
    case GET_GRDUATION_CERTIFICATION:
      return {
        ...state,
        graduationCertifications: payload?.students || [],
      };
    case GET_DAILY_PAYMENTS_WITH_PAYMENT_HISTORY:
      return {
        ...state,
        dailyPaymentWithPaymentHistory: payload?.data?.result || [],
      };
    case GET_ASSESSMENTS_SUBJECT:
      return {
        ...state,
        assesmentsSubjects: payload?.data || [],
      };
    case GET_MARK_STATISTICS:
      return{
        ...state,
        mark_statistics: payload.data || {}
      }
    case GET_STUDENT_MARKS:
      return{
        ...state,
        studentsMarks: payload.data || {}
      }
    case GET_EXPECTED_STUDENT_COUNT:
      return{
        ...state,
        expectedStudentCount: payload.data || []
      }
      
    default:
      return state;
  }
  // return state;
};

export default adminArea;